import React from 'react';
import styled from 'styled-components';
import {
  Keyframes, Spring, animated, config,
} from 'react-spring';
import { graphql } from 'gatsby';
import AppContainer from '../components/AppContainer';
import Next from '../components/Next';
import {
  Heading, SubHeading, Para, Button, ButtonDark, Icon, Panel, Container,
} from '../elements';
import { withMediaKit } from '../components/MediaKit';
import { config as configs } from '../config';
import ScrollableLink from '../components/ScrollableLink';
import Content from '../components/Content';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

const KTrail = Keyframes.Trail({
  peek: {
    x: 0,
    opacity: 1,
    from: { x: -35, opacity: 0 },
    delay: 400,
  },
  open: { x: 0, opacity: 1, delay: 80 },
  close: { x: -25, opacity: 0 },
});

const CoverContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  > a {
    position: absolute;
    left: 50%;
    top: 2rem;
    transform: translateX(-50%);
  }
  .collage {
    clip-path: url(#svg-clipPath);
    background-repeat: no-repeat;
    background-position: right;
    /* background-attachment: fixed;
    background-size: auto; */
  }
`;

const Img = styled.div`
  clip-path: url(#svg-circle-clipPath);
  background-image: url(${props => props.src});
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateZ(1px);
  height: auto;
  background-repeat: no-repeat;
  background-position: right;
  background-attachment: fixed;
  background-size: auto;
  min-height: 400px;
  @media screen and (max-width: 768px) {
    /* clip-path: none; */
    /* position: static;
    max-width: 100%; */
  }
`;

const PageContent = ({ page, mediaKit }) => {
  const items2 = JSON.parse(page.content.content);
  const items3 = items2.content;
  const nodeMap = {
    'heading-1': 'h1',
    'heading-2': 'h2',
    paragraph: 'p',
  };

  const items = [
    <Heading ariaRole="heading" light>
      {page.heading}
    </Heading>,
    <SubHeading>{page.subHeading}</SubHeading>,
    <Content content={page.content.childContentfulRichText.html} />,
  ];

  return (
    <div>
      <KTrail native items={items} keys={items.map((_, i) => i)} state="peek">
        {(item, i) => ({ x, ...props }) => (
          <animated.div
            style={{
              transform: x.interpolate(x => `translate3d(${x}%,0,0)`),
              ...props,
            }}
          >
            {item}
          </animated.div>
        )}
      </KTrail>
      <Spring
        native
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={config.molasses}
        delay={1600}
      >
        {styles => (
          <animated.div style={{ ...styles, marginTop: '4rem' }}>
            <a
              href={mediaKit ? mediaKit.realShortLink : configs.mediaKitFallback}
              target="_blank"
              rel="noopener noreferrer"
              className="no-border"
            >
              <Button style={{ marginRight: '1rem' }}>
                Download our media kit&nbsp;&nbsp;
                <Icon>picture_as_pdf</Icon>
              </Button>
            </a>
            {page.list.listItem.map((item, i) => (
              <ScrollableLink to={item.text.text} key={i} className="no-border">
                <ButtonDark>
                  {item.title}
                  &nbsp;&nbsp;
                  <Icon>{item.icon}</Icon>
                </ButtonDark>
              </ScrollableLink>
            ))}
            <br />
            <Next to="/overview" label="Overview" />
          </animated.div>
        )}
      </Spring>
    </div>
  );
};
const Image = ({ image }) => (
  <CoverContainer>
    <React.Fragment>
      {/*   <Cover className="collage" img={img}/> */}
      <PreviewCompatibleImage
        imageInfo={image}
        style={{
          clipPath: 'url(#svg-circle-clipPath)',
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
          left: 0,
          WebkitTransform: 'translateZ(1px)',
          height: 'auto',
          minHeight: 400,
        }}
      />
      {/* <Img src={fallback} alt="AJN supplements collage" /> */}
      <Spring
        native
        from={{ radius: 0, x: 250, y: 0 }}
        to={{ radius: 100, x: 50, y: 50 }}
        config={{ duration: 1500 }}
      >
        {styles => (
          <svg
            aria-hidden="true"
            version="1.1"
            id="cutout-shape"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1080 1080"
            xmlSpace="preserve"
            width="0"
            height="0"
          >
            <defs>
              <path
                id="svg-shape"
                d="M673.909,0L308.461,384.258c-14.353,15.111-13.773,39.171,1.263,53.543c15.09,14.374,39.168,13.795,53.52-1.261
              l128.914-135.571c-14.352,15.056-13.721,39.169,1.316,53.543c15.088,14.372,39.168,13.795,53.52-1.318L307.516,605.016
              c-14.352,15.056-13.776,39.169,1.316,53.543c15.035,14.374,39.113,13.795,53.467-1.316L534.217,476.5
              c-14.353,15.056-13.774,39.171,1.314,53.543c15.088,14.374,39.115,13.795,53.467-1.263l-98.206,103.246
              c-14.355,15.109-13.776,39.223,1.261,53.596c15.088,14.371,39.168,13.795,53.52-1.318l138.164-145.257
              c-14.352,15.109-13.771,39.223,1.316,53.597c15.037,14.372,39.117,13.793,53.47-1.316l-46.055,48.437
              c-14.357,15.056-13.776,39.169,1.312,53.543c15.037,14.372,39.117,13.795,53.469-1.318L618.335,827.562
              c-14.352,15.111-13.773,39.223,1.263,53.597c15.088,14.372,39.168,13.795,53.522-1.316l147.368-154.945
              c-14.357,15.109-13.776,39.223,1.312,53.595c15.037,14.374,39.117,13.743,53.469-1.315l-227.176,238.868
              c-14.352,15.056-13.773,39.169,1.316,53.545c15.035,14.37,39.115,13.795,53.469-1.318l196.47-206.541
              c-14.354,15.056-13.773,39.171,1.314,53.543c15.09,14.372,39.17,13.793,53.522-1.316L1080,781.68V0H673.909z"
                transform="translate(-80,0)"
                style={{ clipPath: 'url(#svg-circle-clipPath)' }}
              />
              <animated.circle
                id="svg-circle"
                cx={styles.x.interpolate(x => `${x}%`)}
                cy={styles.y.interpolate(y => `${y}%`)}
                r={styles.radius.interpolate(radius => `${radius}%`)}
              />
              <clipPath id="svg-clipPath">
                <use width="100%" xlinkHref="#svg-shape" />
              </clipPath>

              <clipPath id="svg-circle-clipPath">
                <use xlinkHref="#svg-circle" />
              </clipPath>
            </defs>
          </svg>
        )}
      </Spring>
    </React.Fragment>
  </CoverContainer>
);

const HomePage = ({ data: { contentfulPage: page }, mediaKit }) => (
  <AppContainer pageTitle="Home">
    <Container id="content">
      <Panel color="blue">
        <PageContent page={page} mediaKit={mediaKit} />
      </Panel>
      <Panel style={{ padding: 0, minHeight: 400 }}>
        <Image image={page.image} />
      </Panel>
    </Container>
  </AppContainer>
);

export const homePageQuery = graphql`
  query HomePageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      heading
      subHeading
      content {
        content
        childContentfulRichText {
          html
        }
      }
      list {
        listItem {
          title
          text {
            text
          }
          icon
        }
      }
      image {
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;
export default withMediaKit(HomePage);
