import React from 'react';
import ScrollableLink from '../ScrollableLink';
import { Spring, config } from 'react-spring';
import { Icon } from '../../elements';

const Next = ({to, label}) => {
  return (
    <Spring
      config={{...config.gentle, delay: 1000}}
      from={{ opacity: 0, transform: 'translateX(-50px)' }}
      to={{ opacity: 1, transform: 'translateX(0px)'}}
    >
      {style => (
        <div>
          <ScrollableLink to={to}>
            {label}
            <span style={{...style, display: 'inline-block'}}>
              <Icon right >arrow_forward</Icon>
            </span>
          </ScrollableLink>
        </div>
        )}
      </Spring>
  )
}
export default Next;